import React from 'react';

function ChatBubble({
    text,
    onClick,
    disabled
}) {

    return (
        <button
            onClick={onClick}
            disabled={disabled}
            className={` ${disabled ? 'disabled' : ''} text-white w-full bg-black border-2 border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2`}
        >
            {text}
        </button>
    );
}

export default ChatBubble;