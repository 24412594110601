import React, { useEffect, useState } from 'react';
import Tile from './Tile';
import aiService from 'services/ai.service';

function TilesSection() {

    const [tiles, setTiles] = useState([]);

    useEffect(() => {
        if (!tiles.length) {
            getGames();
        }
    }, []);

    const getGames = async () => {
        try {
            const response = await aiService.getGames();
            console.log('Games:', response.data.games);
            setTiles(response.data.games);
        } catch (error) {
            console.log('Error:', error);
        }
    };

    return (
        <section id='games' className="bg-gray-50 dark:bg-gray-900 py-8 md:py-24 antialiased">
            <div className="mx-auto max-w-screen-xl px-4 2xl:px-0">
                {tiles.length > 0 && (
                    <div className="mb-4 md:mb-8 mx-auto max-w-full grid gap-4 lg:grid-cols-3 xl:grid-cols-4">
                        {tiles.map((tile) => (
                            <Tile key={tile._id} {...tile} />
                        ))}
                    </div>
                )}
                {/* Uncomment and adjust the button if needed */}
                {/*
                <div className="w-full text-center">
                    <button
                        type="button"
                        className="rounded-lg border border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-primary-700 dark:hover:text-white focus:outline-none focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 font-medium px-5 py-2.5 text-sm"
                    >
                        Show more
                    </button>
                </div>
                */}
            </div>
        </section>
    );
}

export default TilesSection;