import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import aiService from "services/ai.service";

// components

import Navbar from "components/Navbars/AuthNavbar.js";
import Game from "components/Game/Game";

export default function GamePage() {

    return (
        <>
            <Navbar transparent />

            <section className="bg-gray-50 dark:bg-gray-900 h-screen max-h-screen">
                <Game />
            </section>
        </>
    );
}