import React, { useEffect, useState } from 'react';
import aiService from 'services/ai.service';
import ChatGenerator from './ChatGenerator';
import EditGame from './EditGame';

function GeneratorSection() {

    const [game, setGame] = useState({});
    const [validationErrors, setValidationErrors] = useState({});

    const generateGame = async (tags, prompt) => {
        try {
            const response = await aiService.createNewGame(tags, prompt);
            setGame(response.data.game);
        } catch (error) {
            setValidationErrors(error.response.data);
            console.error(error);
        }
    };

    return (
        <section id='create' className="bg-gray-900 py-8 antialiased">
            {Object.keys(game).length !== 0 ?
                (<EditGame {...game} />)
                :
                (<ChatGenerator generateGame={generateGame} validationErrors={validationErrors} setValidationErrors={setValidationErrors} />)
            }
        </section>
    );
}

export default GeneratorSection;