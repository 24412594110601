import React, { useEffect, useState } from 'react';
import LoadingSpinner from 'components/ui/LoadingSpinner';
import OptionButton from 'components/Chat/OptionButton';
import ChatSidebar from 'components/Chat/ChatSidebar';
import serverConf from 'config/server.config';

function Story({ game }) {
    const [story, setStory] = useState('');
    const [options, setOptions] = useState([]);
    const [isSending, setIsSending] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const [rawResponse, setRawResponse] = useState([]);
    const [retrigger, setRetrigger] = useState(false);

    useEffect(() => {
        if (game && game._id && story === '') {
            continueStory();
        }
    }, [game]);

    function extractOptions(text, streamDone) {
        // Use a case-insensitive search for "Do you" followed by optional punctuation
        const doYouRegex = /do you[\s\S]?:/i;
        const doYouMatch = text.match(doYouRegex);
        let optionsText = '';

        if (doYouMatch) {
            // Find the index where the options start
            const optionsStartIndex = doYouMatch.index + doYouMatch[0].length;

            // Extract the text after "Do you:"
            optionsText = text.substring(optionsStartIndex).trim();
        } else {
            // If "Do you:" is not present, treat the entire text as potential options
            optionsText = text.trim();
        }

        // Split the options text into lines
        const lines = optionsText.split(/\r?\n/);

        const options = [];
        const optionRegex = /^\s*(?:\d+\.?|\*|\-)\s*(.+)\s*$/m; // Ensures valid options end with a line break

        for (let line of lines) {
            const match = line.match(optionRegex);
            if (match) {
                // Extract the option text
                const optionText = match[1].trim();
                options.push({ text: optionText });
            } else if (line.trim() === '') {
                // Skip empty lines
                continue;
            }
        }

        if (streamDone && doYouMatch) {
            setStory(text.substring(0, doYouMatch.index).trim());
        }

        return options;
    }

    async function continueStory() {
        try {
            // Scroll to top of the scrollable container
            const scrollableContainer = document.querySelector('.overflow-y-auto');
            if (scrollableContainer) {
                scrollableContainer.scrollTo({ top: 0, behavior: 'smooth' });
            } else {
                // Fallback to window scroll if no specific container
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
            
            setStory(''); // Reset the story before starting
            setOptions([]);
            setShowOptions(false);
            setIsSending(true);

            const response = await fetch(`${serverConf.HOST}/ai/genStory?gameId=${encodeURIComponent(game.gameId)}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    game: game,
                    messages: rawResponse,
                }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder('utf-8');

            let done = false;
            while (!done) {
                const { value, done: streamDone } = await reader.read();
                if (streamDone) break;

                if (value) {
                    const chunk = decoder.decode(value);
                    setStory(prevStory => {
                        const newStory = prevStory + chunk;

                        // Extract options from the new story
                        const newOptions = extractOptions(newStory, false);

                        // If we find 3 options, set them and stop streaming
                        if (newOptions && newOptions.length === 3 && chunk === '\n') {
                            setOptions(newOptions);
                            setIsSending(false); // Indicate sending is done
                            reader.cancel(); // Stop the stream
                            return newStory.trimStart();
                        }

                        return newStory.trimStart();
                    });
                }
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsSending(false);
        }
    }

    useEffect(() => {
        if (!isSending && story !== '') {
            const newOptions = extractOptions(story, true);
            if (newOptions && newOptions.length > 0) {
                setOptions(newOptions);
                const newMessage = {
                    role: 'assistant',
                    content: `${story}`,
                };
                // Add the new message to the messages array
                setRawResponse(prevRawResponse => [...prevRawResponse, newMessage]);
                setShowOptions(true);
            } else if (!showOptions) {
                setRetrigger(true);
            }
        }
    }, [story, isSending, showOptions]);

    useEffect(() => {
        if (retrigger) {
            setRetrigger(false);
            continueStory();
        }
    }, [retrigger]);

    const handleOptionClick = (option) => {
        const selectedOption = {
            role: 'user',
            content: `${option.text}`,
        };
        // Add the new message to the messages array
        setRawResponse(prevRawResponse => [...prevRawResponse, selectedOption]);
        setOptions([]);
    }

    useEffect(() => {
        if (rawResponse[rawResponse.length - 1]?.role === 'user') {
            continueStory();
        }
    }, [rawResponse]);

    return (
        <div className="flex flex-row items-center justify-center pt-20 pb-10 overflow-y-auto h-full bg-gray-100 dark:bg-gray-800">
            <div className="container flex flex-row h-full min-h-0 justify-center">
                <div className="p-4 w-full flex flex-col justify-between max-w-4xl">

                    <>
                        {story && (
                            <div className="p-4 w-full mx-auto space-y-4 max-w-prose">
                                <p
                                    style={{ whiteSpace: 'pre-wrap' }}
                                    className="text-lg text-blueGray-200 leading-relaxed font-serif gap-x-8"
                                >
                                    {story}
                                </p>
                            </div>
                        )}
                        {showOptions && options.length > 0 &&
                            <div className="w-full py-6 md:px-12 grid">
                                <div className="grid grid-cols-1 gap-1">
                                    {options.map((option, index) => (
                                        <OptionButton
                                            key={index}
                                            {...option}
                                            onClick={() => handleOptionClick(option)}
                                            disabled={false}
                                        />
                                    ))}
                                </div>
                            </div>
                        }
                    </>
                </div>
                <div className="p-4 w-4/12 hidden md:flex">
                    <ChatSidebar {...game} />
                </div>
            </div>
        </div>
    );
}

export default Story;