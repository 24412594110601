import React, { useState, useEffect } from "react";
import SignUpModal from "components/SignUp/SignUpModal";
import authService from "services/auth.service";

const ChatGenerator = ({ generateGame, validationErrors, setValidationErrors }) => {
    const [tags, setTags] = useState([]);
    const [prompt, setPrompt] = useState("");
    const [availableTags, setAvailableTags] = useState([
        "Anal Play",
        "Anal Sex",
        "BDSM",
        "Bisexual",
        "Blowjob",
        "Bondage",
        "Breast Play",
        "Cheating Wife",
        "Creampie",
        "Cumshot",
        "Deepthroat",
        "Dirty Talk",
        "Dominant",
        "Double Penetration",
        "Erotic Massage",
        "Erotica",
        "Exhibitionism",
        "Fellatio",
        "Female Orgasm",
        "Fetish",
        "Fingering",
        "First Time",
        "Foot Fetish",
        "Gay",
        "Group Sex",
        "Handjob",
        "Hentai",
        "Interracial",
        "Kinky",
        "Lace Lingerie",
        "Lesbian",
        "Lingerie",
        "Male Orgasm",
        "Masturbation",
        "MILF",
        "Missionary",
        "Oral Sex",
        "Orgy",
        "Outdoor Sex",
        "Penetration",
        "Pussy Licking",
        "Roleplay",
        "Sadomasochism",
        "Sensual",
        "Sex on the Beach",
        "Sex Toy",
        "Sexual Fantasy",
        "Submission",
        "Swingers",
        "Threesome",
        "Vaginal Sex",
        "Voyeurism",
        "Watersports",
        "Wife Swap",
        "Busty",
        "Pierced Nipples",
        "Sexy Stockings",
        "Tattooed",
        "Tight Pussy",
        "Tiny Tits",
        "Upskirt",
        "Wet Pussy",
        "White Panties",
        "Big Cock",
        "Big Tits",
        "Black Cock",
        "Blonde",
        "Brunette",
        "Creampie Gangbang",
        "Cum on Tits",
        "Facial",
        "Gangbang",
        "Giant Cock",
        "Group Blowjob",
        "Hardcore",
        "Interracial Gangbang",
        "Lingerie Models",
        "Long Hair",
        "Massage Parlor",
        "Natural Tits",
        "Nude Beach",
        "Outdoor Blowjob",
        "Peeing",
        "Petite",
        "Pissing",
        "Public Blowjob",
        "Public Sex",
        "Real Amateur",
        "Redhead",
        "Sexy Lingerie",
        "Shaved Pussy",
        "Skirt",
        "Small Tits",
        "Squirting",
        "Stockings",
        "Stripper",
        "Tall",
        "Tan Lines",
        "Teen",
        "Thick Cock",
        "Tight Ass",
        "Titfuck",
        "Uniform",
        "Upskirt Panties",
        "Vibrators",
        "Vintage",
        "Virgin",
        "Webcam",
        "Wet T-Shirt",
        "White Cock",
        "Yellow Panties",
        "69",
        "Amateur Anal",
        "Amateur Blowjob",
        "Anal Beads",
        "Anal Fingering",
        "Anal Licking",
        "Anal Toy",
        "Arab",
        "Asian",
        "Ass Fucking",
        "Ass Licking",
        "Ass Play",
        "Babe",
        "Babysitter",
        "Ball Gag",
        "Bareback",
        "Bath",
        "Bathroom",
        "Beach Sex",
        "Bed",
        "Begging",
        "Behind",
        "Bikini",
        "Bisexual Threesome",
        "Black Pussy",
        "Blonde Pussy",
        "Bodybuilder",
        "Bondage Tape",
        "Boobs",
        "Booty",
        "Boss",
        "Boyfriend",
        "Brazilian",
        "Brazen",
        "Breastfeeding",
        "Bukkake",
        "Busty Milf",
        "Buttplug",
        "Caress",
        "Caucasian",
        "CFNM",
        "Chastity Belt",
        "Cheating",
        "Cheerleader",
        "Chubby",
        "Climax",
        "Close Up",
        "Cock Sucking",
        "Coed",
        "College",
        "Colombian",
        "Condom",
        "Cowboy",
        "Cowgirl",
        "Crack",
        "Cream Pie",
        "Cum",
        "Cum in Mouth",
        "Cum on Face",
        "Cum on Tits",
        "Cum Swapping",
        "Cunt",
        "Cunilingus",
        "Curly Hair",
        "Deep Throat",
        "Defloration",
        "Denim",
        "Dirty",
        "Dildo",
        "Dilf",
        "Doctor",
        "Doggy Style",
        "Domina",
        "Dominant Female",
        "Dominatrix",
        "DP",
        "Drunk",
        "Duct Tape",
        "Duo",
        "Eat Pussy",
        "Ebony",
        "Ejaculation",
        "Electro",
        "English",
        "Enormous",
        "Erotic Dance",
        "Erotic Massage",
        "Erotic Sex",
        "Escort",
        "Exhibitionist",
        "Exotic",
        "Extreme",
        "Face Fucking",
        "Face Sitting",
        "Facial Cumshot",
        "Faker",
        "Fanny",
        "Fantasy",
        "Father",
        "Femdom",
        "Fetishwear",
        "Ffm",
        "Ffmm",
        "Fingered",
        "Fingering",
        "First Anal",
        "First Time",
        "Fist Fucking",
        "Fisting",
        "Flexible",
        "Floppy Tits",
        "Foot Job",
        "Foot Worship",
        "Foreplay",
        "French",
        "French Kissing",
        "Friend",
        "Fuck Machine",
        "Funny",
        "Furry",
        "Futanari",
        "Gag",
        "Gagging",
        "Game",
        "Gang Bang",
        "Gaping",
        "Garter",
        "Garter Belt",
        "Gay Anal",
        "Gay Blowjob",
        "Gay Sex",
        "German",
        "Girlfriend",
        "Glamour",
        "Glasses",
        "Glory Hole",
        "Gloves",
        "Golden Shower",
        "Goth",
        "Grandma",
        "Grandpa",
        "Greek",
        "Hairy",
        "Handcuffs",
        "Handjob",
        "Happy Ending",
        "Hardcore Sex",
        "Hard Dick",
        "Harem",
        "Head",
        "Head Shaving",
        "Hentai",
        "Herbal",
        "Heterosexual",
        "Hidden Cam",
        "High Heels",
        "Hijab",
        "Hindu",
        "Hole",
        "Home Made",
        "Honey",
        "Honeymoon",
        "Horny",
        "Hospital",
        "Host",
        "Hot",
        "Hot Milf",
        "Hot Sex",
        "Hotel",
        "Housewife",
        "Huge Cock",
        "Huge Tits",
        "Humiliation",
        "Hung",
        "Husband",
        "Ice",
        "Impregnation",
        "Incest",
        "Indian",
        "Inflatable",
        "Interracial",
        "Intruder",
        "Irish",
        "Italian",
        "Jail",
        "Japanese",
        "Jewelry",
        "Jewish",
        "Kegel",
        "Kinky",
        "Kiss",
        "Kitchen",
        "Korean",
        "Latex",
        "Lederhosen",
        "Legs",
        "Lesbian",
        "Lez",
        "Lick",
        "Licking",
        "Lingerie",
        "Lipstick",
        "Little",
        "Living Room",
        "Lolita",
        "Long Dick",
        "Long Hair",
        "Long Tongue",
        "Luxury",
        "Lycra",
        "Machine",
        "Mad",
        "Magic",
        "Maid",
        "Maid Uniform",
        "Major",
        "Make",
        "Makeup",
        "Male",
        "Male Escort",
        "Male Strippers",
        "Marathon",
        "Marriage",
        "Marry",
        "Masochism",
        "Massage",
        "Massage Oil",
        "Master",
        "Masturbation",
        "Mature",
        "Mature Milf",
        "Mature Women",
        "Metal",
        "Mexican",
        "Mf",
        "Mfm",
        "Mfmm",
        "Midget",
        "Midnight",
        "Mighty",
        "Milking",
        "Mistress",
        "Mm",
        "Mmf",
        "Mmfmm",
        "Mmm",
        "Money",
        "Money Shot",
        "Monogamy",
        "Motel",
        "Mother",
        "Mother Daughter",
        "Mother Son",
        "Muscled",
        "Muscle",
        "Muslim",
        "Myth",
        "Nasty",
        "Natural",
        "Naughty",
        "Newbie",
        "News",
        "Nightgown",
        "Nudist",
        "Nurse",
        "Nympho",
        "Obssession",
        "Office",
        "Officer",
        "Oil",
        "Old",
        "Olympic",
        "Orgasm",
        "Orgy",
        "Overheard",
        "Parody",
        "Party",
        "Pawg",
    ]);
    const [loading, setLoading] = useState(false);
    const [suggestedTags, setSuggestedTags] = useState([/* 5 random tags */]);
    const [showSignup, setShowSignup] = useState(false);
    const [currentUser, setCurrentUser] = useState(authService.getCurrentUser());


    useEffect(() => {
        handleRefreshTags();
    }, []);

    useEffect(() => {
        if (showSignup === false) {
            setLoading(false);
        }
    }, [showSignup])

    useEffect(() => {
        if (Object.values(validationErrors).length > 0) {
            setLoading(false);
        }
    }, [validationErrors])

    const handleTagsChange = (e) => {
        const inputValue = e.target.value;

        // Check if the input contains a comma
        if (inputValue.includes(',')) {
            // Split the input value by commas
            const parts = inputValue.split(',');

            // Extract tags before the last comma, trim them, and filter out empty strings
            const newTags = parts.slice(0, -1).map(tag => tag.trim()).filter(tag => tag.length > 0);

            // Update the tags array with the new tags
            setTags((prevTags) => [...prevTags, ...newTags]);

            // Reset the input field to the text after the last comma
            e.target.value = parts[parts.length - 1];
        }
    };

    const handlePromptChange = (e) => {
        setPrompt(e.target.value);
    };

    const handleAddTag = (tag) => {
        if (!tags.includes(tag)) {
            setTags((prevTags) => [...prevTags, tag]);
            // remove the tag from the suggestedTags
            setAvailableTags((prevTags) => prevTags.filter((i) => i !== tag));
            replaceSuggestedTag(tag);
        }
    };

    const handleRemoveTag = (tag) => {
        setTags((prevTags) => prevTags.filter((i) => i !== tag));
        // add the tag back to the suggestedTags
        setAvailableTags((prevTags) => [...prevTags, tag]);
    };

    const replaceSuggestedTag = (tag) => {
        let randomIndex;
        let maxRetries = 10;
        let i = 0;
        randomIndex = Math.floor(Math.random() * availableTags.length);
        while (suggestedTags.includes(availableTags[randomIndex]) && i < maxRetries) {
            randomIndex = Math.floor(Math.random() * availableTags.length);
            i++;
        }
        setSuggestedTags((prevTags) => {
            const newTags = [...prevTags];
            const tagIndex = newTags.indexOf(tag);
            if (tagIndex !== -1) {
                newTags[tagIndex] = availableTags[randomIndex];
            }
            if (i === maxRetries) {
                newTags.splice(tagIndex, 1);
            }
            return newTags;
        });
    };

    const handleRefreshTags = () => {
        // select 5 random tags from the suggestedTags
        const randomTags = [];
        let iterationCount = 0;
        while (randomTags.length < 5 && iterationCount < 50) {
            const randomIndex = Math.floor(Math.random() * availableTags.length);
            const randomTag = availableTags[randomIndex];
            if (!tags.includes(randomTag) && !randomTags.includes(randomTag)) {
                randomTags.push(randomTag);
            }
            iterationCount++;
        }
        const newTags = [...randomTags];
        setSuggestedTags(newTags);
    };

    const handleGenerateStory = () => {
        setValidationErrors({});
        if (currentUser) {
            setLoading(true);
            generateGame(tags, prompt);
        } else {
            setShowSignup(true);
        }
    }

    return (
        <>
            {showSignup ? (
                <SignUpModal setShowSignup={setShowSignup} />
            ) : null
            }
            <div className="flex flex-col items-center bg-blueGray-900 text-white py-10 px-4">
                <h1 className="text-3xl font-bold mb-6">Turn your <span className="text-red-500">Fantasy</span> into a Game </h1>
                <div className="w-full max-w-2xl">
                    <div className="mb-6">
                        <label className="block text-lg mb-2">Select some Tags to be included in your game</label>
                        <div className="flex flex-wrap gap-2 mb-4">
                            {tags.map((tag, index) => (
                                <span key={index} className="bg-blueGray-800 px-4 py-1 rounded-full border border-gray-600 text-sm flex items-center">
                                    {tag}
                                    <button
                                        onClick={() => handleRemoveTag(tag)}
                                        className="ml-2 text-red-500"
                                    >
                                        &times;
                                    </button>
                                </span>
                            ))}
                        </div>
                        <input
                            type="text"
                            className="w-full px-4 py-2 rounded-lg bg-blueGray-800 border border-gray-600 focus:outline-none focus:border-red-500"
                            onChange={handleTagsChange}
                            placeholder="Add your own tags here..."
                        />
                        <div className="flex gap-2 mt-4 overflow-x-auto">
                            <button
                                onClick={handleRefreshTags}
                                className="bg-blueGray-800 px-4 py-1 rounded-full text-sm whitespace-nowrap"
                                style={{ outline: 'none' }}
                                onFocus={(e) => e.target.style.outline = 'none'}
                            >
                                <i className="fas fa-refresh"></i>
                            </button>
                            {suggestedTags.map((tag, index) => (
                                <button
                                    key={index}
                                    onClick={() => handleAddTag(tag)}
                                    className="bg-blueGray-800 px-4 py-1 rounded-full text-sm whitespace-nowrap"
                                    style={{ outline: 'none' }}
                                    onFocus={(e) => e.target.style.outline = 'none'}
                                >
                                    {tag}
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className="mb-6">
                        <label className="block text-lg mb-2">Any special wishes?</label>
                        <textarea
                            className="w-full px-4 py-2 rounded-lg bg-blueGray-800 border border-gray-600 focus:outline-none focus:border-red-500"
                            value={prompt}
                            onChange={handlePromptChange}
                            placeholder="The more you include, the more specific your game will be..."
                        ></textarea>
                    </div>
                    {validationErrors && validationErrors.category && (
                        <div className="mb-4 text-red-500">
                            <span className="font-bold">{`${validationErrors.category}: `}</span>
                            {validationErrors.explanation_if_violating}
                        </div>
                    )}
                    <button
                        onClick={handleGenerateStory}
                        className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-6 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                        {loading ? (
                            <svg
                                className="animate-spin h-5 w-5 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                ></circle>
                                <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                            </svg>
                        ) : (
                            'Generate Game'
                        )}
                    </button>
                </div>
            </div>
        </>
    );
};

export default ChatGenerator;