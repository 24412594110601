import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import aiService from "services/ai.service";

// components

import Navbar from "components/Navbars/AuthNavbar.js";
import Story from "components/Story/Story";

export default function StoryPage() {
    const { gameId } = useParams();
    const [game, setGame] = useState({});

    useEffect(() => {
        console.log(`Game: ${gameId}`);
        loadGame(gameId);
    }, [gameId]);

    const loadGame = async (gameId) => {
        try {
            const response = await aiService.loadGame(gameId);
            setGame(response.data.game);
        } catch (error) {
            //show no game found page
            console.error(error);
        }
    };

    return (
        <>
            <Navbar transparent />

            <section className="bg-gray-50 dark:bg-gray-900 h-screen max-h-screen">
                <Story game={game} />
            </section>
        </>
    );
}