import React from 'react';


const AgePopup = ({ closePopup }) => {

    const handleModalClick = (event) => {
        event.stopPropagation(); // Stop the event from propagating to the parent container
    }

    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-100 outline-none focus:outline-none"
            >
                <div
                    className="relative w-auto my-6 mx-10_100 max-w-3xl"
                    onClick={handleModalClick}
                >
                    {/*body*/}
                    <div className="relative p-4 w-full max-w-lg h-full md:h-auto">
                        <div className="relative px-8 py-6 bg-white rounded-lg shadow dark:bg-gray-800 md:p-8">
                            <div className="mb-8 text-sm font-light text-gray-500 dark:text-gray-400">
                                <div className="mb-8 text-4xl font-bold text-gray-900 text-center dark:text-white">
                                    <h3 className='block md:hidden'>🔞 Adult content</h3>
                                    <h3 className='hidden md:block'>⚠ Adult content ⚠</h3>
                                </div>
                                <p className='text-lg text-center'>
                                    This website contains explicit content and is only available for users over 18 years old.
                                </p>
                            </div>
                            <div className="justify-between items-center pt-0 space-y-4 space-x-0 md:space-x-4 sm:flex sm:space-y-0">
                                <button
                                    onClick={() => closePopup(false)}
                                    type="button"
                                    className="py-2 px-4 w-full text-sm text-center font-medium text-red-500 bg-transparent rounded-lg border border-red-500 sm:w-auto">
                                    I'm younger than 18
                                </button>
                                <button
                                    onClick={() => closePopup(true)}
                                    type="button"
                                    className="py-2 px-4 w-full text-sm font-medium text-center text-white rounded-lg bg-red-500 sm:w-auto">
                                    I'm 18 or older
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed inset-0 z-40 bg-black opacity-75"></div>
        </>
    );
};

export default AgePopup;