/* eslint-disable */
import React, { useState } from "react";
import authService from "services/auth.service";
import UserDropdown from "components/Dropdowns/UserDropdown";
import SignUpModal from "components/SignUp/SignUpModal";


export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(authService.getCurrentUser());
  const [showSignup, setShowSignup] = useState(false);

  return (
    <>
      {showSignup && <SignUpModal setShowSignup={setShowSignup} />}
      <nav className="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-black-50">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <a
              className="text-2xl font-bold flex flex-row items-center text-gray-900 dark:text-white leading-relaxed inline-block mr-4 py-2 whitespace-nowrap"
              href="/"
            >
              <img src="/android-chrome-512x512.png" alt="logo" className="h-10 mr-4" />
              AI CHYOA
            </a>
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none text-gray-900 dark:text-white"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fas fa-bars"></i>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center lg:bg-opacity-0 lg:shadow-none" +
              (navbarOpen ? " block" : " hidden")
            }
            id="example-navbar-warning"
          >
            {currentUser ? (
              <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
                <li className="flex items-center justify-end">
                  <UserDropdown user={currentUser} />
                </li>
              </ul>
            ) : (
              <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
                <li className="flex items-center">
                  <button
                    className="text-white uppercase bg-gradient-to-br from-pink-500 to-orange-400 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-orange-500 font-medium rounded-lg text-sm px-4 py-2 text-center"
                    type="button"
                    onClick={() => setShowSignup((prevShowSignup) => !prevShowSignup)}
                  >
                    Join Now
                  </button>
                </li>
              </ul>
            )}
          </div>
        </div>
      </nav>
    </>
  );
}