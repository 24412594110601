import React, { useState, useEffect } from 'react';
import defaultAvatar from '../../assets/img/defaultAvatar.png';
import serverConf from 'config/server.config';

function ChatBubble({
    msg,
    game,
    isSending,
    isSender,
    avatar,
}) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [avatarUrl, setAvatarUrl] = useState('https://via.placeholder.com/150x150');

    useEffect(() => {
        setAvatarUrl(`${serverConf.HOST}/image/${avatar}`);
    }, [avatar]);

    return (
        <>
            {(msg.type === 'character' || msg.type === 'player') && (
                <>
                    <div
                        className={`flex items-start gap-2.5 w-full ${isSender ? 'flex-row-reverse' : ''
                            }`}
                    >
                        <img
                            className={`${isSender ? 'ml-2' : 'mr-2'} w-16 h-16 rounded-full`}
                            src={avatarUrl ? avatarUrl : defaultAvatar}
                            alt={`${game.name} avatar`}
                        />
                        <div
                            className={`flex ${isSender ? 'items-end md:w-6/12 w-11/12' : 'md:w-8/12 w-11/12'
                                } flex-col rounded-lg px-4 py-1 bg-gray-100 rounded-e-xl rounded-es-xl dark:bg-gray-700`}
                        >
                            <div className="flex items-center space-x-2 rtl:space-x-reverse">
                                <span className="text-base font-semibold text-gray-900 dark:text-white">
                                    {msg.name}
                                </span>
                            </div>
                            <p className="text-base font-normal py-2.5 text-gray-900 dark:text-gray-200">
                                {msg.text}
                            </p>
                            {/*
                            {isSending && isSender && (
                                <div className="flex items-end space-x-2 w-full justify-end">
                                    <LoadingSpinner />
                                    <p className="text-sm text-gray-500 dark:text-gray-400">
                                        Sending message...
                                    </p>
                                </div>
                            )}
                            */}
                        </div>
                        {/*
                        <div className="relative">
                            <button
                                onClick={() => setDropdownOpen(!dropdownOpen)}
                                className="inline-flex self-center items-center p-2 text-sm font-medium text-gray-900 bg-transparent rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-transparent dark:hover:bg-gray-800 dark:focus:ring-gray-600"
                                type="button"
                            >
                                <svg
                                    className="w-4 h-4 text-gray-500 dark:text-gray-400"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 4 15"
                                >
                                    <path d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
                                </svg>
                            </button>
                            {dropdownOpen && (
                                <div className="absolute z-10 right-0 bg-white divide-y divide-gray-100 rounded-lg shadow w-40 dark:bg-gray-700 dark:divide-gray-600">
                                    <ul
                                        className="py-2 text-sm text-gray-700 dark:text-gray-200"
                                        aria-labelledby="dropdownMenuIconButton"
                                    >
                                        {msg.options.map((option, index) => (
                                            <li key={index}>
                                                <button
                                                    onClick={() => {
                                                        // Handle option click
                                                        setDropdownOpen(false);
                                                        console.log(`${option} clicked`);
                                                    }}
                                                    className="w-full text-left block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                                >
                                                    {option}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                        */}
                    </div>
                </>
            )}
            {(msg.type === 'narrator' || msg.type === 'narration') && (
                <div className='flex flex-col w-full'>
                    <div
                        className={`flex items-start gap-2.5 w-full ${isSender ? 'flex-row-reverse' : ''
                            }`}
                    >
                        <p
                            className={`text-base w-11/12 md:w-8/12 text-gray-900 dark:text-gray-200 ${isSender ? 'text-right' : ''
                                }`}
                        >
                            {msg.text}
                        </p>
                    </div>
                    {/*}
                    {isSending && isSender && (
                        <div className="flex items-end space-x-2 w-full justify-end">
                            <LoadingSpinner />
                            <p className="text-sm text-gray-500 dark:text-gray-400">
                                Sending message...
                            </p>
                        </div>
                    )}
                    */}
                </div>
            )}
        </>
    );
}

export default ChatBubble;