import React, { useEffect, useState, useRef } from 'react';
import serverConf from 'config/server.config';

function Game({ dialog, character }) {

    const [modifiedMessage, setModifiedMessage] = useState('');

    useEffect(() => {
        if (!dialog) return;
        if (dialog.message) {
            let modifiedMessage = dialog.message.includes(':')
                ? dialog.message.split(':').slice(1).join(':').trim()
                : dialog.message;
            setModifiedMessage(modifiedMessage);
        }
    }, [dialog]);

    function capitalizeWords(str) {
        return str.split(' ') // Split the string into an array of words
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
            .join(' '); // Join the array back into a string
    }

    return (
        <div className="absolute bottom-0 w-full h-1/5 bg-black-90 text-white font-semibold">
            <div className="flex flex-row h-full items-start px-12 relative">
                {/* Image placed with absolute positioning */}
                {dialog?.character.toLowerCase() !== 'narrator' && (
                    <img
                        className="absolute h-150px rounded-lg border-white border-4"
                        src={`${serverConf.HOST}/image/${character?.avatar || '66f47c6dee484acecb5f01d4'}`}
                        alt="Character Avatar"
                        style={{ left: "5%", top: "-33%" }}
                    />
                )}
                {dialog && (
                    <div className="flex flex-col items-start pt-2 ml-200px">
                        <span className="text-lg font-bold pb-2">{capitalizeWords(dialog.character)}</span>
                        <span className="text-base">
                            {modifiedMessage}
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Game;