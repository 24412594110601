import React, { useState, useEffect, useRef } from 'react';
import serverConf from "config/server.config";

function Tile({
  image,
  avatar,
  name,
  description,
  tags,
  gameId
}) {
  const [imageUrl, setImageUrl] = useState('https://via.placeholder.com/1024x640');
  const [truncatedDescription, setTruncatedDescription] = useState(description);
  const descriptionRef = useRef(null);

  useEffect(() => {
    setImageUrl(`${serverConf.HOST}/image/${image}`);
  }, [image]);

  useEffect(() => {
    const element = descriptionRef.current;
    if (element.scrollHeight > element.clientHeight) {
      let truncated = description;
      while (element.scrollHeight > element.clientHeight && truncated.length > 0) {
        truncated = truncated.slice(0, -1);
        element.innerText = truncated + '...';
      }
      setTruncatedDescription(truncated + '...');
    }
  }, [description]);

  return (
    <div className="w-full max-w-full rounded-lg border border-gray-200 bg-white shadow-sm dark:border-gray-700 dark:bg-gray-800">
      <div className="w-full">
        <a href={`/play/${gameId}`}>
          <img className="h-full rounded-t-lg" src={imageUrl} alt={name} />
        </a>
      </div>
      <div className="p-6">
        <a
          href={`/play/${gameId}`}
          className="text-2xl font-semibold leading-tight text-gray-900 hover:underline dark:text-white"
        >
          {name}
        </a>

        <ul className="mt-2 flex items-center gap-4">
          {tags.slice(0, 4).map((tag, index) => (
            <li key={index} className="flex items-center gap-2">
              <p className="text-sm font-medium text-gray-500 dark:text-gray-400 line-clamp-2">{tag}</p>
            </li>
          ))}
        </ul>

        <p
          ref={descriptionRef}
          className="mt-2 text-base text-gray-700 dark:text-gray-300"
          style={{ height: '8em', overflow: 'hidden' }}
        >
          {truncatedDescription}
        </p>

        <div className="mt-4 flex items-center justify-between gap-4">
          <a href={`/play/${gameId}`}>
            <button
              type="button"
              className="inline-flex items-center rounded-lg bg-sky-400 hover:bg-sky-500 px-5 py-2.5 text-sm font-medium text-white focus:outline-none focus:ring-4 focus:ring-sky-300 dark:bg-sky-600 dark:hover:bg-sky-700 dark:focus:ring-sky-800"
            >
              <i className="fas fa-comment mr-2"></i>
              Chat Mode
            </button>
          </a>
          <a href={`/story/${gameId}`}>
            <button
              type="button"
              className="inline-flex items-center rounded-lg bg-sky-400 hover:bg-sky-500 px-5 py-2.5 text-sm font-medium text-white focus:outline-none focus:ring-4 focus:ring-sky-300 dark:bg-sky-600 dark:hover:bg-sky-700 dark:focus:ring-sky-800"
            >
              <i className="fas fa-pencil mr-2"></i>
              Story Mode
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Tile;