import React from "react";
import { Routes, Route } from "react-router-dom";

// views without layouts

import Landing from "views/Landing.js";
import Privacy from "views/Privacy";
import ChatPage from "views/ChatPage";
import GamePage from "views/GamePage";
import GeneratorPage from "views/GeneratorPage";
import StoryPage from "views/StoryPage";


function App() {
    
    return (
        <Routes>
            <Route path="/download" element={<></>} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/*" element={<Landing />} />
            <Route path="/play/:gameId" element={<ChatPage />} />
            <Route path="/game" element={<GamePage />} />
            <Route path="/create" element={<GeneratorPage />} />
            <Route path="/story/:gameId" element={<StoryPage />} />
        </Routes>
    );
}

export default App;