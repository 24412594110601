import React, { useState } from 'react';

const LoadButton = ({onClick}) => {

  return (
    <div className="absolute right-6 bottom-6 group self-end">
      <button
        type="button"
        aria-controls="speed-dial-menu-default"
        onClick={onClick}
        className="flex items-center justify-center text-white bg-blueGray-700 border rounded-lg w-14 h-14"
      >
        <i className="fas fa-arrow-down"></i>
      </button>
    </div>
  );
};

export default LoadButton;