import api from "./api";

const URL_PREFIX = '/ai/';

class AiService {
  genMessage(gameId, game, messages) {
    let url = `${URL_PREFIX}genMessage?gameId=${encodeURIComponent(gameId)}`;
    return api.post(url, { game, messages });
  }

  loadGame(gameId) {
    let url = `${URL_PREFIX}loadGame?gameId=${encodeURIComponent(gameId)}`;
    return api.get(url);
  }

  getGames() {
    let url = `${URL_PREFIX}getGames`;
    return api.get(url);
  }

  genGameScene(gameId, game, messages, characters) {
    let url = `${URL_PREFIX}genGameScene?gameId=${encodeURIComponent(gameId)}`;
    return api.post(url, { game, messages, characters });
  }

  createNewGame(tags, prompt) {
    let url = `${URL_PREFIX}createNewGame`;
    return api.post(url, { tags, prompt });
  }

  genStory(gameId, game, messages) {
    let url = `${URL_PREFIX}genStory?gameId=${encodeURIComponent(gameId)}`;
    return api.post(url, { game, messages });
  }
}

const aiServiceInstance = new AiService();

export default aiServiceInstance;