import React, { useEffect, useState, useRef } from 'react';
import serverConf from 'config/server.config';
import LoadingSpinner from 'components/ui/LoadingSpinner';
//import backgroundImage from '../../assets/img/game_background.png';
import GameDialog from './GameDialog';
import aiService from 'services/ai.service';
import OptionButton from 'components/Chat/OptionButton';

function Game({ game }) {
    const [messages, setMessages] = useState([]);
    const [options, setOptions] = useState([]);
    const [characters, setCharacters] = useState([]);
    const [isSending, setIsSending] = useState(false);
    const [currentDialog, setCurrentDialog] = useState(0);
    const [currentCharacter, setCurrentCharacter] = useState({});
    const [backgroundImage, setBackgroundImage] = useState('');
    const [showOptions, setShowOptions] = useState(false);
    const [rawResponse, setRawResponse] = useState([]);

    useEffect(() => {
        continueGame();
    }, []);

    async function continueGame() {
        try {
            const response = await aiService.genGameScene(null, null, rawResponse, characters);
            console.log('Response:', response);
            //concat new messages to existing messages
            setCurrentDialog(0);
            setMessages(response.data.dialogue);
            if (response.data.characters) {
                setCharacters(prevCharacters => [...prevCharacters, ...response.data.characters]);
            }
            setOptions(response.data.options);
            if (response.data.backgroundImage) {
                setBackgroundImage(response.data.backgroundImage);
            }
            setShowOptions(false);
            setRawResponse(prevRawResponse => [...prevRawResponse, response.data.rawResponse]);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsSending(false);
        }
    }

    useEffect(() => {
        if (messages && messages.length > 0 && currentDialog < messages.length && characters && characters.length > 0) {
            console.log('Messages:', messages);
            console.log('currentDialog:', currentDialog);
            console.log('Current dialog:', messages[currentDialog]);
            console.log('First dialog:', messages[0]);
            console.log("Characters: ", characters);
            console.log("Current Message Character: ", messages[currentDialog].character);

            const foundCharacter = characters.find(character => character.name === messages[currentDialog].character);
            console.log("Found Character: ", foundCharacter);

            setCurrentCharacter(foundCharacter);
        }
    }, [messages, currentDialog, characters]);

    useEffect(() => {
        console.log('Messages:', messages);
    }, [messages]);

    useEffect(() => {
        console.log('current Character:', currentCharacter);
        console.log('current Dialog:', currentDialog);
        console.log('Messages:', messages);
    }, [currentCharacter, currentDialog]);

    const handleClick = () => {
        if (currentDialog < messages.length - 1) {
            console.log('Current Dialog:', currentDialog);
            setCurrentDialog(currentDialog + 1);
        } else {
            console.log('All Dialogs completed');
            setShowOptions(true);
        }
    };

    const handleOptionClick = (option) => {
        console.log('Option clicked:', option);
        const newMessage = {
            role: 'user',
            content: `${option.id}`,
        };
        // Add the new message to the messages array
        setRawResponse(prevRawResponse => [...prevRawResponse, newMessage]);
        setOptions([]);
        setShowOptions(false);
        setIsSending(true);
    }

    useEffect(() => {
        if (isSending) {
            continueGame();
        }
    }, [isSending]);


    return (
        <div className="flex flex-row items-center justify-center pt-20 pb-10 h-screen max-h-screen h-full bg-gray-50 dark:bg-gray-900">
            <div className="container flex flex-row h-full min-h-0">
                <div className="p-4 w-full flex flex-col justify-between overflow-hidden">
                    <div
                        onClick={() => handleClick()}
                        className="relative flex flex-col w-full mx-auto space-y-4 overflow-y-auto h-full max-h-full h-full items-start mb-4 bg-white dark:bg-gray-800 rounded-lg"
                        style={{
                            backgroundImage: `url(${`${serverConf.HOST}/image/${backgroundImage}`})`,
                            backgroundSize: "cover",
                            filter: "brightness(1.0)",
                        }}
                    >
                        {!showOptions && messages && messages.length > 0 && currentDialog < messages.length &&
                            <GameDialog dialog={messages[currentDialog]} character={currentCharacter} />
                        }
                        {showOptions && options.length > 0 &&
                            <div className="absolute bottom-0 w-full py-6 px-12 bg-black-90 grid">
                                <div className="grid grid-cols-1 gap-1">
                                    {options.map((option, index) => (
                                        <OptionButton
                                            key={index}
                                            {...option}
                                            onClick={() => handleOptionClick(option)}
                                            disabled={false}
                                        />
                                    ))}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Game;