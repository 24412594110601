class TokenService {
  getLocalRefreshToken() {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.refreshToken;
  }

  getLocalAccessToken() {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.accessToken;
  }

  updateLocalAccessToken(token) {
    let user = JSON.parse(localStorage.getItem("user"));
    user.accessToken = token;
    localStorage.setItem("user", JSON.stringify(user));
  }

  getUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  setUser(user, remember) {
    if (!remember) {
      user.refreshToken = "";
    }
    localStorage.setItem("user", JSON.stringify(user));
  }

  updateUser(updatedUser) {
    // Retrieve user object from local storage
    const storedUser = this.getUser();

    // Check if the stored user exists
    if (storedUser) {
      // Update properties present in newUser
      for (const key in updatedUser) {
        if (updatedUser.hasOwnProperty(key) && storedUser.hasOwnProperty(key) && key !== "roles") {
          storedUser[key] = updatedUser[key];
        }
      }
      
      // Update the stored user object
      localStorage.setItem("user", JSON.stringify(storedUser));
    }
  }

  removeUser() {
    localStorage.removeItem("user");
  }
}

const tokenServiceInstance = new TokenService();

export default tokenServiceInstance;