import api from "./api";

const URL_PREFIX = '/newsletter/';

class NewsletterService {
  newSub(email) {
    return api.post(URL_PREFIX + "newSub", {
      email,
    });
  }
}

const newsletterServiceInstance = new NewsletterService();

export default newsletterServiceInstance;