import React, { useState } from 'react';
import authService from 'services/auth.service';

const SignUpModal = ({ setShowSignup }) => {
    // State variables to store form data
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [termsAccepted, setTermsAccepted] = useState(false);

    // State for error messages
    const [errors, setErrors] = useState({});

    // State to toggle between signup and login forms
    const [isSignUp, setIsSignUp] = useState(true);

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate form inputs
        const validationErrors = {};

        if (!email) {
            validationErrors.email = 'Email is required';
        } else {
            // Basic email format validation
            const emailRegex = /\S+@\S+\.\S+/;
            if (!emailRegex.test(email)) {
                validationErrors.email = 'Please enter a valid email address';
            }
        }

        if (!password) {
            validationErrors.password = 'Password is required';
        }

        if (isSignUp) {
            // Additional validation for signup
            if (!confirmPassword) {
                validationErrors.confirmPassword = 'Please confirm your password';
            } else if (password !== confirmPassword) {
                validationErrors.confirmPassword = 'Passwords do not match';
            }

            /*
            if (!termsAccepted) {
                validationErrors.termsAccepted = 'You must accept the terms and conditions';
            }
            */
        }

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            // Clear errors
            setErrors({});

            if (isSignUp) {
                try {
                    await authService.register(email, password);
                    alert('Check your email to verify your account');
                    // Reset form fields
                    setEmail('');
                    setPassword('');
                    setConfirmPassword('');
                    setTermsAccepted(false);

                } catch (error) {
                    console.error('Error:', error);
                    const errorMessage = error.response?.data?.message || 'An error occurred';
                    validationErrors.confirmPassword = errorMessage;
                    setErrors(validationErrors);
                }
            } else {
                try {
                    const response = await authService.login(email, password, true);
                    // Reset form fields
                    setEmail('');
                    setPassword('');
                    setConfirmPassword('');
                    setTermsAccepted(false);
                    setShowSignup(false);
                    //refresh page
                    window.location.reload();

                } catch (error) {
                    console.error('Error:', error);
                    const errorMessage = error.response?.data?.message || 'An error occurred';
                    validationErrors.password = errorMessage;
                    setErrors(validationErrors);
                }
            }
        }
    };

    return (
        <>
            <div
                className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50"
                onClick={() => setShowSignup(false)}
            >
                <div
                    className="bg-white rounded-lg shadow dark:bg-gray-800 w-full max-w-md mx-auto"
                    onClick={(e) => e.stopPropagation()}
                >
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <div className="flex justify-between items-center">
                            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                {isSignUp ? 'Sign up for free' : 'Login to your account'}
                            </h1>
                            <button
                                onClick={() => setShowSignup(false)}
                                className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                            >
                                <svg
                                    className="w-6 h-6"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586
                     l4.293-4.293a1 1 0 111.414 1.414L11.414
                      10l4.293 4.293a1 1 0 01-1.414 1.414L10
                       11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586
                        10 4.293 5.707a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </button>
                        </div>
                        <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                            <div>
                                <label
                                    htmlFor="email"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Your email
                                </label>
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    className={`bg-gray-50 border ${errors.email ? 'border-red-500' : 'border-gray-300'
                                        } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white`}
                                    placeholder="name@company.com"
                                    required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                {errors.email && (
                                    <p className="text-red-500 text-xs mt-1">{errors.email}</p>
                                )}
                            </div>
                            <div>
                                <label
                                    htmlFor="password"
                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >
                                    Password
                                </label>
                                <input
                                    type="password"
                                    name="password"
                                    id="password"
                                    placeholder="••••••••"
                                    className={`bg-gray-50 border ${errors.password ? 'border-red-500' : 'border-gray-300'
                                        } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white`}
                                    required
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                {errors.password && (
                                    <p className="text-red-500 text-xs mt-1">
                                        {errors.password}
                                    </p>
                                )}
                            </div>

                            {isSignUp && (
                                <>
                                    <div>
                                        <label
                                            htmlFor="confirm-password"
                                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                        >
                                            Confirm password
                                        </label>
                                        <input
                                            type="password"
                                            name="confirmPassword"
                                            id="confirm-password"
                                            placeholder="••••••••"
                                            className={`bg-gray-50 border ${errors.confirmPassword
                                                ? 'border-red-500'
                                                : 'border-gray-300'
                                                } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white`}
                                            required
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                        />
                                        {errors.confirmPassword && (
                                            <p className="text-red-500 text-xs mt-1">
                                                {errors.confirmPassword}
                                            </p>
                                        )}
                                    </div>
                                    {/* Terms and conditions */}
                                    {/*
                                    <div className="flex items-start">
                                        <div className="flex items-center h-5">
                                            <input
                                                id="terms"
                                                aria-describedby="terms"
                                                type="checkbox"
                                                className={`w-4 h-4 border ${errors.termsAccepted
                                                    ? 'border-red-500'
                                                    : 'border-gray-300'
                                                    } rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600`}
                                                required
                                                checked={termsAccepted}
                                                onChange={(e) => setTermsAccepted(e.target.checked)}
                                            />
                                        </div>
                                        <div className="ml-3 text-sm">
                                            <label
                                                htmlFor="terms"
                                                className="font-light text-gray-500 dark:text-gray-300"
                                            >
                                                I accept the{' '}
                                                <a
                                                    className="font-medium text-blue-600 hover:underline dark:text-blue-500"
                                                    href="#"
                                                >
                                                    Terms and Conditions
                                                </a>
                                            </label>
                                        </div>
                                    </div>
                                    {errors.termsAccepted && (
                                        <p className="text-red-500 text-xs mt-1">
                                            {errors.termsAccepted}
                                        </p>
                                    )}
                                    */}
                                </>
                            )}

                            <button
                                type="submit"
                                className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                            >
                                {isSignUp ? 'Create an account' : 'Login'}
                            </button>
                            <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                                {isSignUp ? (
                                    <>
                                        Already have an account?{' '}
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setIsSignUp(false);
                                                setErrors({});
                                            }}
                                            className="font-medium text-blue-600 hover:underline dark:text-blue-500"
                                        >
                                            Login here
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        Don't have an account?{' '}
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setIsSignUp(true);
                                                setErrors({});
                                            }}
                                            className="font-medium text-blue-600 hover:underline dark:text-blue-500"
                                        >
                                            Sign up here
                                        </button>
                                    </>
                                )}
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SignUpModal;