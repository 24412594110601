import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import aiService from "services/ai.service";

// components

import Navbar from "components/Navbars/AuthNavbar.js";
import ChatGenerator from "components/Generator/ChatGenerator.js";
import EditGame from "components/Generator/EditGame";


export default function GeneratorPage() {

    const [game, setGame] = useState({});

    const generateGame = async (tags, prompt) => {
        const response = await aiService.createNewGame(tags, prompt);
        setGame(response.data.game);
    };

    useEffect(() => {
        getGames();
    }, []);

    const getGames = async () => {
        try {
            const response = await aiService.getGames();
            console.log('Games:', response.data.games);
            setGame(response.data.games[0]);
        } catch (error) {
            console.log('Error:', error);
        }
    };

    return (
        <>
            <Navbar transparent />

            <section className="bg-gray-50 dark:bg-gray-900 h-screen max-h-screen">
                <ChatGenerator generateGame={generateGame} />
                <EditGame {...game} />
            </section>
        </>
    );
}