import React, { useState, useEffect } from 'react';
import serverConf from 'config/server.config';
import EditCharacter from './EditCharacter';

const EditGame = ({
    image,
    avatar,
    name,
    description,
    tags,
    gameId,
    characters
}) => {

    const [imageUrl, setImageUrl] = useState('https://via.placeholder.com/1024x640');

    useEffect(() => {
        if (image) {
            setImageUrl(`${serverConf.HOST}/image/${image}`);
        }
    }, [image]);

    return (
        <section className="py-8 bg-white md:py-16 dark:bg-gray-900 antialiased">
            <div className="max-w-screen-xl px-4 mx-auto 2xl:px-0">
                <div className="lg:grid lg:grid-cols-2 lg:gap-8 xl:gap-16">
                    <div className="shrink-0 mx-auto">

                        <h1 className="text-4xl mb-2 font-semibold text-gray-900 sm:text-2xl dark:text-white">
                            {name}
                        </h1>

                        {tags &&
                            <ul className="mb-8 flex items-center gap-4">
                                {tags.slice(0, 4).map((tag, index) => (
                                    <li key={index} className="flex items-center">
                                        <p className="text-sm font-medium text-gray-500 dark:text-gray-400 line-clamp-2">{tag}</p>
                                    </li>
                                ))}
                            </ul>
                        }

                        <img
                            className="w-full hidden dark:block"
                            src={imageUrl}
                            alt=""
                        />
                    </div>

                    <div className="mt-6 sm:mt-8 lg:mt-0">


                        <p className="mb-6 text-gray-500 dark:text-gray-400">
                            {description}
                        </p>

                        <div className="mt-6 sm:gap-4 sm:items-center sm:flex sm:mt-8">
                            <div className="mt-4 flex items-center justify-between gap-4">
                                <a href={`/play/${gameId}`}>
                                    <button
                                        type="button"
                                        className="inline-flex items-center rounded-lg bg-sky-400 hover:bg-sky-500 px-5 py-2.5 text-sm font-medium text-white focus:outline-none focus:ring-4 focus:ring-sky-300 dark:bg-sky-600 dark:hover:bg-sky-700 dark:focus:ring-sky-800"
                                    >
                                        <i className="fas fa-comment mr-2"></i>
                                        Chat Mode
                                    </button>
                                </a>
                                <a href={`/story/${gameId}`}>
                                    <button
                                        type="button"
                                        className="inline-flex items-center rounded-lg bg-sky-400 hover:bg-sky-500 px-5 py-2.5 text-sm font-medium text-white focus:outline-none focus:ring-4 focus:ring-sky-300 dark:bg-sky-600 dark:hover:bg-sky-700 dark:focus:ring-sky-800"
                                    >
                                        <i className="fas fa-pencil mr-2"></i>
                                        Story Mode
                                    </button>
                                </a>
                            </div>
                        </div>

                        <hr className="my-6 md:my-8 border-gray-200 dark:border-gray-800" />

                        {characters &&
                            <div className="mb-4 md:mb-8 grid gap-4">
                                {characters.map((character) => (
                                    <EditCharacter key={character._id} {...character} />
                                ))}
                            </div>
                        }

                    </div>
                </div>
            </div>
        </section>
    );
};

export default EditGame;