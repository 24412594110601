import api from "./api";
import TokenService from "./token.service";

const URL_PREFIX = '/auth/';

class AuthService {
    async login(email, password, remember) {
        const response = await api
            .post(URL_PREFIX + "signin", {
                email,
                password
            });
        if (response.data.accessToken) {
            TokenService.setUser(response.data, remember);
        }
        return response.data;
    }

    async checkUser() {
        const response = await api
            .get(URL_PREFIX + "check");
        return response.status === 200;
    }

    logout() {
        TokenService.removeUser();
        window.location.replace('/');
    }

    register(email, password) {
        return api.post(URL_PREFIX + "signup", {
            email,
            password,
        });
    }

    getCurrentUser() {
        return TokenService.getUser();
    }
}

const authServiceInstance = new AuthService();

export default authServiceInstance;